import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LazyLoad from "react-lazyload"

import useContentful from "../../utils/hooks/useContentful"
import { Device, MetadataReadonly } from "../../types/contentfulTypes"
import { mockupsCount } from "../../data/MockupsData"

import Layout from "../../components/layout/Layout"
import * as TextStyles from "../../components/styles/TextStyles"
import TextSection from "../../components/sections/TextSection"
import CollectionsSection from "../../components/sections/CollectionsSection"
import CollectionCard from "../../components/cards/CollectionCard"
import CollectionCardList from "../../components/lists/CollectionCardList"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const AndroidMockups = (props: Props) => {
  const { data } = props
  const { getCollections } = useContentful()
  const [collections, setCollections] = useState<Device[]>([])

  useEffect(() => {
    getCollections("Android", "Android").then(response => response && setCollections(response))
    return () => setCollections([])
  }, [])

  return (
    <Layout {...data}>
      <Wrapper>
        <Container>
          <StaticImage
            className="illustration"
            src="../../images/illustrations/illustration11.webp"
            alt="illustration"
          />
          <TextContainer>
            <Title>{mockupsCount["Android"]}</Title>
            <TextSection
              title="Android Mockups"
              description="Our latest library packs all the new Android device mockups that comes with various angles to present your designs."
            />
          </TextContainer>
        </Container>
        <LazyLoad>
          <CollectionCardList collections={collections} />
          <CollectionsSection collections={collections} />
        </LazyLoad>
        <CollectionContainer>
          <CollectionCard
            title="iOS"
            link="/collections/ios"
            image="../../images/illustrations/collection-ios.png"
          />
          <CollectionCard
            title="Desktop"
            link="/collections/desktop"
            image="../../images/illustrations/collection-desktop.png"
          />
        </CollectionContainer>
      </Wrapper>
    </Layout>
  )
}

export default AndroidMockups

export const query = graphql`
  query AndroidMockupsQuery {
    contentfulMetadata(slug: { eq: "android-mockups" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  padding: 200px 0px;
`

const Container = styled.div`
  display: grid;
  max-width: 1234px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    gap: 100xp;
  }

  .illustration {
    max-width: 675px;
    margin: 0 auto;
  }
`

const TextContainer = styled.div`
  display: grid;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .purchase {
    display: none;
  }
`

const Title = styled(TextStyles.H1)`
  font-style: normal;
  font-weight: 300;
  font-size: 140px;
  line-height: 167px;
  padding: 0 20px;

  @media (max-width: 414px) {
    font-size: 100px;
  }
`

const CollectionContainer = styled.div`
  display: grid;
  max-width: 880px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 20px;
  padding: 180px 20px 0;
  margin: 0 auto;
`
